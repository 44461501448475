<template>
    <div>
        <page-header
            :title="$t('pages.PaymentSources.PaymentSources')"
            @onClose="$router.go(-1)"
            show-back
        />
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <error-view
            v-else-if="blockingError !== null"
            image-path-in-assets="error-icon.svg"
            :title="blockingError.title"
            :description="blockingError.description"
            :cta="blockingError.cta"
            @click="blockingErrorButtonClicked"
        />
        <div v-else>
            <!-- IF NO PAYMENT SOURCES -->
            <div
                v-if="paymentSources.length < 1"
                class="container"
            >
                <no-payment-sources />
            </div>

            <!-- HAS PAYMENT SOURCES -->
            <div v-else>
                <list-row
                    v-for="(paymentSource, index) in paymentSources"
                    :key="index"
                    :title="capitalize(paymentSource.accountType)"
                    :value="'Ending in ' + last4Chars(paymentSource.maskedAccountNumber)"
                    value-class="text-muted"
                    @onClick="handleSelectPaymentSource(paymentSource)"
                    show-arrow
                />
                <div class="container d-grid">
                    <base-button
                        button-classes="btn btn-secondary my-2"
                        @click="$router.push('paymentSourceForm')"
                    >
                        {{ $t('pages.PaymentSources.AddAccount') }}
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import ListRow from '@/components/ListRow'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { logger } from '@/utils/logger'
    import NoPaymentSources from '@/components/NoPaymentSources'
    import ErrorView from '@/components/ErrorView'
    import BaseButton from '@/components/base/BaseButton'
    import { mapActions, mapState } from 'vuex'
    import format from '@/mixins/format'
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'PaymentSource',
        components: {
            BaseButton,
            PageHeader,
            ListRow,
            LoadingIndicator,
            NoPaymentSources,
            ErrorView,
        },
        mixins: [format],
        data() {
            return {
                loading: false,
                error: false,
                errorText: '',
                submitting: false,
                blockingError: null,
                secondaryCardHolderAutoPayMessage: '',
            }
        },
        computed: {
            ...mapState(['paymentSources', 'selectedPaymentSource']),
        },
        mounted: async function () {
            this.$logEvent('view_payment_sources')
            this.loading = true
            await this.getFundingSources()
            this.loading = false
        },
        methods: {
            ...mapActions(['fetchPaymentSources']),
            getFundingSources: async function () {
                try {
                    logger.info('getting payment sources')
                    await this.fetchPaymentSources()
                } catch (e) {
                    logger.info('problem getting payment sources', e)
                    this.errorText = i18n.t('pages.PaymentSources.GetPaymentSourcesError')
                }
            },
            handleSelectPaymentSource: function (paymentSource) {
                this.$store.dispatch('selectPaymentSource', paymentSource)
                this.$router.push('paymentSourceDetail')
            },
            blockingErrorButtonClicked: async function () {
                this.loading = true
                await Promise.all([this.getAutopayDetails(), this.getFundingSources()])
                this.loading = false
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/components/appBase';
    @import '../styles/components/appHeader.scss';
</style>
