<template>
    <div class="text-center">
        <img
            src="@/assets/images/components/noPaymentSources/NoPaymentSources.svg"
            width="200"
            height="160"
            :alt="$t('pages.PaymentSource.BillingAccountNotFound')"
        >
        <p class="mt-2 mb-2">
            {{ $t('pages.PaymentSource.BillingAccountNotFound') }}
        </p>
        <p class="text-muted text-center mb-4">
            {{ $t('pages.PaymentSource.AddBank') }}
        </p>
        <base-button
            class="mb-2"
            @click="$router.push({ name: 'PaymentSourceForm', query: redirect })"
        >
            {{ $t('pages.PaymentSource.AddPaymentMethod') }}
        </base-button>
    </div>
</template>

<script>
    import BaseButton from '@/components/base/BaseButton'
    export default {
        name: 'NoPaymentSources',
        props: {
            redirect: {
                type: Object,
                default: function () {
                    return {}
                },
            },
        },
        components: {
            BaseButton,
        },
    }
</script>
